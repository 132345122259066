<template>
	<div class="comingsoon-page">
		<div class="coming-area">
			<div class="flicker">
				<p class="title">Shakeme</p>
			</div>
		</div>

		<div class="advertise-area">
			<p>現在開催準備中イベント</p>
			<a class="advertise" href="/event/isakasyuzou/e8635071-43fe-42a7-ac5a-ca8e47ee2d3f">
				<div class="bg-image"><NuxtImg src="/img/IMG_3983.jpg" /></div>
				<div class="title"><small>日本酒イベント</small>蔵人入門～田植え編～<small>2025.06.21[SAT]-22[SUN]</small><small>in IBARAKI SATOMI</small></div>
			</a>
		</div>
		<div class="advertise-area">
			<p>終了済みのイベント</p>
			<a class="advertise" href="/event/hayashi/c75d808f-b09e-4638-83aa-a9ae88173f61">
				<div class="bg-image"><NuxtImg src="/img/site-background.jpg" /></div>
				<div class="title"><small>日本酒フェス</small>茨城愛があふれる会<small>2024.09.28 [SAT]</small><small>in MARUNOUCHI BASE</small></div>
			</a>
		</div>
		<div class="coming-footer">copyright &copy; 2024 PATURN Ltd. All Rights Reserved.</div>
	</div>
</template>

<script setup>
	useHead({
		bodyAttrs: {
			class: 'event-page'
		}
	})

</script>