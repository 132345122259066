import { default as howtouse1Ef5q44nmtMeta } from "D:/workspace/shakeme/client/pages/document/howtouse.vue?macro=true";
import { default as howtouse2DDsEkrBMxsMeta } from "D:/workspace/shakeme/client/pages/document/howtouse2.vue?macro=true";
import { default as sctaE6ckFNvaXNMeta } from "D:/workspace/shakeme/client/pages/document/scta.vue?macro=true";
import { default as termsofuseFZGUInrnwSMeta } from "D:/workspace/shakeme/client/pages/document/termsofuse.vue?macro=true";
import { default as _91event_code_939RVC836QejMeta } from "D:/workspace/shakeme/client/pages/event/[eventer]/[event_code].vue?macro=true";
import { default as c75d808f_45b09e_454638_4583aa_45a9ae88173f61F58br6uV0EMeta } from "D:/workspace/shakeme/client/pages/event/[eventer]/c75d808f-b09e-4638-83aa-a9ae88173f61.vue?macro=true";
import { default as indextonctJmAAiMeta } from "D:/workspace/shakeme/client/pages/event/index.vue?macro=true";
import { default as indexdmlrGEvtdKMeta } from "D:/workspace/shakeme/client/pages/index.vue?macro=true";
import { default as editGVZ1XW0DPcMeta } from "D:/workspace/shakeme/client/pages/member/edit.vue?macro=true";
import { default as form_eventyJbPlPwMLLMeta } from "D:/workspace/shakeme/client/pages/member/event/[event_code]/form_event.vue?macro=true";
import { default as _91sakagura_code_932TY4Chr5ClMeta } from "D:/workspace/shakeme/client/pages/member/event/[event_code]/form_sakagura/[sakagura_code].vue?macro=true";
import { default as indexahlFOsCT1DMeta } from "D:/workspace/shakeme/client/pages/member/event/[event_code]/form_sakagura/index.vue?macro=true";
import { default as indexf2n1MW909VMeta } from "D:/workspace/shakeme/client/pages/member/event/[event_code]/index.vue?macro=true";
import { default as _91quiz_code_9368iCyQMBobMeta } from "D:/workspace/shakeme/client/pages/member/event/[event_code]/quiz/[quiz_code].vue?macro=true";
import { default as _91ticket_code_93yfCXg3zt60Meta } from "D:/workspace/shakeme/client/pages/member/gift/[ticket_code].vue?macro=true";
import { default as indexQmsh3jyvloMeta } from "D:/workspace/shakeme/client/pages/member/index.vue?macro=true";
import { default as _91order_code_93t3aLhFGTXnMeta } from "D:/workspace/shakeme/client/pages/member/order/[order_code].vue?macro=true";
import { default as _91ticket_code_93eoKAbCCU5OMeta } from "D:/workspace/shakeme/client/pages/member/qr/[ticket_code].vue?macro=true";
import { default as _91order_code_93a5DHoO4KykMeta } from "D:/workspace/shakeme/client/pages/member/receipt/[order_code].vue?macro=true";
export default [
  {
    name: "document-howtouse",
    path: "/document/howtouse",
    meta: howtouse1Ef5q44nmtMeta || {},
    component: () => import("D:/workspace/shakeme/client/pages/document/howtouse.vue").then(m => m.default || m)
  },
  {
    name: "document-howtouse2",
    path: "/document/howtouse2",
    meta: howtouse2DDsEkrBMxsMeta || {},
    component: () => import("D:/workspace/shakeme/client/pages/document/howtouse2.vue").then(m => m.default || m)
  },
  {
    name: "document-scta",
    path: "/document/scta",
    meta: sctaE6ckFNvaXNMeta || {},
    component: () => import("D:/workspace/shakeme/client/pages/document/scta.vue").then(m => m.default || m)
  },
  {
    name: "document-termsofuse",
    path: "/document/termsofuse",
    meta: termsofuseFZGUInrnwSMeta || {},
    component: () => import("D:/workspace/shakeme/client/pages/document/termsofuse.vue").then(m => m.default || m)
  },
  {
    name: "event-eventer-event_code",
    path: "/event/:eventer()/:event_code()",
    meta: _91event_code_939RVC836QejMeta || {},
    component: () => import("D:/workspace/shakeme/client/pages/event/[eventer]/[event_code].vue").then(m => m.default || m)
  },
  {
    name: "event-eventer-c75d808f-b09e-4638-83aa-a9ae88173f61",
    path: "/event/:eventer()/c75d808f-b09e-4638-83aa-a9ae88173f61",
    meta: c75d808f_45b09e_454638_4583aa_45a9ae88173f61F58br6uV0EMeta || {},
    component: () => import("D:/workspace/shakeme/client/pages/event/[eventer]/c75d808f-b09e-4638-83aa-a9ae88173f61.vue").then(m => m.default || m)
  },
  {
    name: "event",
    path: "/event",
    meta: indextonctJmAAiMeta || {},
    component: () => import("D:/workspace/shakeme/client/pages/event/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexdmlrGEvtdKMeta || {},
    component: () => import("D:/workspace/shakeme/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "member-edit",
    path: "/member/edit",
    meta: editGVZ1XW0DPcMeta || {},
    component: () => import("D:/workspace/shakeme/client/pages/member/edit.vue").then(m => m.default || m)
  },
  {
    name: "member-event-event_code-form_event",
    path: "/member/event/:event_code()/form_event",
    meta: form_eventyJbPlPwMLLMeta || {},
    component: () => import("D:/workspace/shakeme/client/pages/member/event/[event_code]/form_event.vue").then(m => m.default || m)
  },
  {
    name: "member-event-event_code-form_sakagura-sakagura_code",
    path: "/member/event/:event_code()/form_sakagura/:sakagura_code()",
    meta: _91sakagura_code_932TY4Chr5ClMeta || {},
    component: () => import("D:/workspace/shakeme/client/pages/member/event/[event_code]/form_sakagura/[sakagura_code].vue").then(m => m.default || m)
  },
  {
    name: "member-event-event_code-form_sakagura",
    path: "/member/event/:event_code()/form_sakagura",
    meta: indexahlFOsCT1DMeta || {},
    component: () => import("D:/workspace/shakeme/client/pages/member/event/[event_code]/form_sakagura/index.vue").then(m => m.default || m)
  },
  {
    name: "member-event-event_code",
    path: "/member/event/:event_code()",
    meta: indexf2n1MW909VMeta || {},
    component: () => import("D:/workspace/shakeme/client/pages/member/event/[event_code]/index.vue").then(m => m.default || m)
  },
  {
    name: "member-event-event_code-quiz-quiz_code",
    path: "/member/event/:event_code()/quiz/:quiz_code()",
    meta: _91quiz_code_9368iCyQMBobMeta || {},
    component: () => import("D:/workspace/shakeme/client/pages/member/event/[event_code]/quiz/[quiz_code].vue").then(m => m.default || m)
  },
  {
    name: "member-gift-ticket_code",
    path: "/member/gift/:ticket_code()",
    meta: _91ticket_code_93yfCXg3zt60Meta || {},
    component: () => import("D:/workspace/shakeme/client/pages/member/gift/[ticket_code].vue").then(m => m.default || m)
  },
  {
    name: "member",
    path: "/member",
    meta: indexQmsh3jyvloMeta || {},
    component: () => import("D:/workspace/shakeme/client/pages/member/index.vue").then(m => m.default || m)
  },
  {
    name: "member-order-order_code",
    path: "/member/order/:order_code()",
    meta: _91order_code_93t3aLhFGTXnMeta || {},
    component: () => import("D:/workspace/shakeme/client/pages/member/order/[order_code].vue").then(m => m.default || m)
  },
  {
    name: "member-qr-ticket_code",
    path: "/member/qr/:ticket_code()",
    meta: _91ticket_code_93eoKAbCCU5OMeta || {},
    component: () => import("D:/workspace/shakeme/client/pages/member/qr/[ticket_code].vue").then(m => m.default || m)
  },
  {
    name: "member-receipt-order_code",
    path: "/member/receipt/:order_code()",
    meta: _91order_code_93a5DHoO4KykMeta || {},
    component: () => import("D:/workspace/shakeme/client/pages/member/receipt/[order_code].vue").then(m => m.default || m)
  }
]